import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useCheckboxGroup,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconAt,
  IconBrandLinkedin,
  IconBriefcase,
  IconBuilding,
  IconCircleX,
  IconCrosshair,
  IconDotsVertical,
  IconEdit,
  IconFileTypeCsv,
  IconLayoutColumns,
  IconTrash
} from '@tabler/icons-react'
import Autolinker from 'autolinker'
import pluralize from 'pluralize'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { App, Apps } from '../../../types/App'
import { Crm } from '../../../types/Crm'
import { PageMeta } from '../../../types/PageMeta'
import { StaticList } from '../../../types/StaticList'
import { useAssignAccounts } from '../../data/use-claim-account'
import { useRemoveListItems } from '../../data/use-list-items'
import { useUrlFilters } from '../../data/use-url-filters'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { BulkActionBar } from '../../ui/BulkActionBar'
import { ColumnInfo, ColumnSelectionDropdown, defaultAccountColumns, useColumns } from '../../ui/ColumnSelector'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import DownloadCsvMenuItem from '../../ui/DownloadCsvButtons'
import EmptyState from '../../ui/EmptyState'
import { UserCircleDashedIcon } from '../../ui/icons'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { usePermission } from '../../ui/PermissionsContext'
import { projectPath } from '../../ui/ProjectsContext'
import { TableFooter } from '../../ui/TableFooter'
import { TopBarContent } from '../../ui/TopBarContext'
import { UserSelector } from '../../ui/UserSelector'
import { FacetFilters } from '../accounts'
import { FilterPreview } from '../accounts/components/FilterPreview'
import { AccountList } from '../icps/icp/account-list'
import { mergeParams } from '../icps/types'
import { ImportType } from '../imports/components/ImportType'
import { ProfileList } from '../profiles/components/profile-list'
import { EditListModal } from './components/EditListModal'

interface Props {
  list: StaticList
  total_in_list?: number
  records: any[]
  crm?: Crm
  apps: Apps
  filters?: FacetFilters
  columns: string[]
  range?: 'day' | 'week' | 'month' | 'all' | 'any' | null
  sort_by?: string
  page_meta: PageMeta
}

const defaultProfileColumns: ColumnInfo[] = [
  {
    id: 'Title',
    label: 'Title',
    key: 'title',
    icon: IconBriefcase
  },
  {
    id: 'Email',
    label: 'Email',
    key: 'email',
    icon: IconAt
  },
  {
    id: 'LinkedIn',
    label: 'LinkedIn',
    key: 'linkedin_url',
    icon: IconBrandLinkedin
  },
  {
    id: 'Company',
    label: 'Company',
    key: 'company',
    icon: IconBuilding
  }
]

export default function Show(props: Props) {
  const recordType = props.list.kind === 'account' ? 'account' : 'contact'
  const apps = useMemo(() => Object.values(props.apps), [props.apps])

  const [list, setList] = useState<StaticList>(props.list)

  useEffect(() => {
    setList(props.list)
  }, [props.list])

  const defaultColumns = props.list.kind === 'account' ? defaultAccountColumns : defaultProfileColumns

  const { columns, loadingColumns, onColumnChange, onColumnRemove } = useColumns({
    data: props.records,
    columns: props.columns?.length ? props.columns : defaultColumns.map((c) => c.key),
    initialColumns: props.columns?.length ? props.columns : defaultColumns.map((c) => c.key)
  })

  const listQuery = `?facets[lists.id]=${props.list.id}`

  const facets = useUrlFilters({
    initialRange: props.range || 'any',
    initialFacets: props.filters,
    initialSortBy: props.sort_by,
    facetCloudPath: props.list.kind === 'profile' ? '/profiles/facet-cloud' : '/accounts/facet-cloud'
  })

  const { hasPermission: canSaveChanges } = usePermission({ on: 'project', action: 'can_manage_accounts' })

  const hasChanges = useMemo(() => {
    const savedColumns = list.display_settings?.display_columns || []
    const savedSort = list.display_settings?.sort_by || null

    if (props.sort_by !== savedSort) {
      return true
    }

    if (columns.length !== savedColumns.length) {
      return true
    }

    return columns.some((column, index) => column !== savedColumns[index])
  }, [columns, props.sort_by, list.display_settings])

  const checkboxes = useCheckboxGroup()
  const selectedRecords = checkboxes.value as string[]

  const editListModal = useDisclosure()
  const deleteDisclosure = useDisclosure()

  const [saving, setSaving] = useState(false)

  return (
    <PageLayout flush gap={0}>
      <PageTitle skipRendering>{list.name}</PageTitle>

      <TopBarContent>
        <Flex width="100%" alignItems="center" justifyContent="space-between" gap={2}>
          <Flex alignItems="center" gap={2}>
            <Text fontSize="15px" fontWeight="semibold">
              {list.name}
            </Text>
            <ImportType kind={props.list.kind === 'account' ? 'accounts' : 'contacts'} />
          </Flex>

          <Flex alignItems="center" gap={2}>
            {hasChanges && (
              <form method="POST" onSubmit={() => setSaving(true)}>
                <AuthenticityToken />
                <input type="hidden" name="_method" value="PUT" />
                {columns.map((column) => (
                  <input
                    key={`input:column:${column}`}
                    type="hidden"
                    name="static_list[display_settings][display_columns][]"
                    value={column}
                  />
                ))}
                <input type="hidden" name="static_list[display_settings][sort_by]" value={facets.sortBy || ''} />

                <Button type="submit" size="sm" colorScheme="purple" isDisabled={!canSaveChanges} isLoading={saving}>
                  Save
                </Button>
              </form>
            )}

            <ColumnSelectionDropdown
              audienceKind={props.list.kind}
              apps={apps}
              selectedColumns={columns}
              onChange={onColumnChange}
            >
              <Button
                variant="outline"
                size="sm"
                flex="none"
                iconSpacing={1.5}
                leftIcon={<IconLayoutColumns size={18} />}
              >
                Columns
              </Button>
            </ColumnSelectionDropdown>

            <Menu>
              <MenuButton size="sm" as={IconButton} icon={<IconDotsVertical size={16} />} variant="outline" />
              <MenuList fontSize="sm" zIndex="popover">
                <MenuItem
                  icon={<IconFileTypeCsv size={16} />}
                  as={Link}
                  href={projectPath(`/${list.kind}s/imports/new?list_id=${list.id}`)}
                >
                  Import CSV
                </MenuItem>
                <DownloadCsvMenuItem
                  apps={apps}
                  audienceKind={list.kind}
                  initialColumns={columns}
                  allowColumnSelection
                />
                <MenuItem icon={<IconEdit size={16} />} onClick={editListModal.onOpen} isDisabled={!canSaveChanges}>
                  Rename list
                </MenuItem>
                <MenuItem
                  icon={<IconTrash size={16} />}
                  color="red.500"
                  onClick={deleteDisclosure.onOpen}
                  isDisabled={!canSaveChanges}
                >
                  Delete list
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </TopBarContent>

      <EditListModal list={list} {...editListModal} onUpdate={(list) => setList((prev) => ({ ...prev, ...list }))} />

      <DeleteConfirmation
        title={`Delete ${list.name}?`}
        deletePath={projectPath(`/lists/${list.id}`)}
        confirmLabel="Delete list"
        isCentered
        {...deleteDisclosure}
      >
        Are you sure you want to delete{' '}
        <Text as="span" fontWeight="semibold">
          {list.name}
        </Text>
        ? This action can not be undone.
      </DeleteConfirmation>

      {list.description && (
        <Stack fontSize="sm" spacing="0.5" px={3} pt={3}>
          <Text
            sx={{
              '.url-description': {
                textDecoration: 'underline',
                fontWeight: 'medium'
              }
            }}
            dangerouslySetInnerHTML={{
              __html: Autolinker.link(list.description, {
                truncate: { length: 64, location: 'end' },
                className: 'url-description'
              })
            }}
          />
          {props.list.created_by_user?.name && (
            <Text color="gray.500" fontSize="xs">
              Created by {props.list.created_by_user.name}
            </Text>
          )}
        </Stack>
      )}

      {props.total_in_list ? (
        <Box py={3} px={3}>
          <FilterPreview
            {...facets}
            canClearFilters={false}
            kind={list.kind}
            apps={props.apps}
            facetValuesPath={
              (list.kind === 'profile' ? '/profiles/facet-values' : '/accounts/facet-values') + listQuery
            }
          />
        </Box>
      ) : (
        <EmptyState
          size="md"
          heading={`No ${recordType}s in this list`}
          description={`Add a ${recordType} to this list or import a CSV`}
          icon={IconCrosshair}
        />
      )}

      {props.records.length > 0 ? (
        <Box>
          <Box marginTop="-1px">
            {props.list.kind === 'profile' ? (
              <ProfileList
                profiles={props.records}
                facetParams={facets}
                selected={checkboxes.value}
                getCheckboxProps={checkboxes.getCheckboxProps}
                onSelectAll={() => {
                  checkboxes.setValue((prev) =>
                    prev.length === props.records.length ? [] : props.records.map((p) => p.id)
                  )
                }}
                range="any"
                apps={apps}
                crm={props.crm}
                canAddColumns
                columns={columns}
                loadingColumns={loadingColumns}
                onColumnChange={onColumnChange}
                onColumnRemove={onColumnRemove}
                sortingBy={facets.sortBy}
                onSortChange={facets.setSortBy}
              />
            ) : props.list.kind === 'account' ? (
              <>
                <AccountList
                  accounts={props.records}
                  facetParams={facets}
                  selected={checkboxes.value}
                  getCheckboxProps={checkboxes.getCheckboxProps}
                  onSelectAll={() => {
                    checkboxes.setValue((prev) =>
                      prev.length === props.records.length ? [] : props.records.map((p) => p.id)
                    )
                  }}
                  range="any"
                  apps={apps}
                  crm={props.crm}
                  showActions={false}
                  canAddColumns
                  columns={columns}
                  loadingColumns={loadingColumns}
                  onColumnChange={onColumnChange}
                  onColumnRemove={onColumnRemove}
                  sortingBy={facets.sortBy}
                  onSortChange={facets.setSortBy}
                />
              </>
            ) : null}
            <TableFooter
              word={recordType}
              pageMeta={props.page_meta}
              page={props.page_meta.current_page}
              prevPath={mergeParams(window.location.toString(), {
                page: props.page_meta.prev_page?.toString() ?? '1'
              })}
              nextPath={mergeParams(window.location.toString(), {
                page: props.page_meta.next_page?.toString() ?? '1'
              })}
              sticky
              px={3}
            />
          </Box>
        </Box>
      ) : Object.keys(props.filters || {}).length > 0 ? (
        <EmptyState
          size="md"
          heading={`No ${recordType}s in this list match your filters`}
          description={`Try adjusting your filters`}
          icon={IconCrosshair}
          ctaText="Clear filters"
          onClick={facets.clearFilters}
        />
      ) : null}

      <BulkActionBar selectionCount={checkboxes.value.length ?? 0} onRemoveSelection={() => checkboxes.setValue([])}>
        {props.list.kind === 'account' && (
          <AssignKoalaOwner app={props.apps?.['Apps::Koala::App']} selectedRecords={selectedRecords} />
        )}
        <RemoveFromList selectedRecords={selectedRecords} listId={props.list.id} />
      </BulkActionBar>
    </PageLayout>
  )
}

interface AssignKoalaOwnerProps {
  app?: App
  selectedRecords: string[]
}

function AssignKoalaOwner(props: AssignKoalaOwnerProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const formId = 'assign-koala-owners-form'

  // TODO if they are all assigned to the same owner currently, select that one by default
  const [assigneeId, setAssigneeId] = useState<string | null>(null)

  const { isLoading, mutateAsync: assignAccounts } = useAssignAccounts()

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault()

      try {
        await assignAccounts({ accountIds: props.selectedRecords, assigneeId })
        toast.success('Assigned owner in Koala!')
        onClose()
      } catch (error: any) {
        toast.error('Failed to assign owner in Koala', { description: error?.message })
        console.error(error)
      }
    },
    [assigneeId, assignAccounts, onClose, props.selectedRecords]
  )

  const isDisabled = props.app?.actions?.koala_claim_account?.enabled === false

  return (
    <>
      <Tooltip label={isDisabled ? 'This action is disabled in your workspace' : undefined}>
        <Button
          size="sm"
          variant="outline"
          leftIcon={<UserCircleDashedIcon size={16} />}
          iconSpacing={1.5}
          onClick={onOpen}
          isLoading={isLoading}
          isDisabled={props.app?.actions?.koala_claim_account?.enabled === false}
        >
          Assign owners
        </Button>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />

          <ModalBody paddingTop={6}>
            <form id={formId} onSubmit={onSubmit}>
              <Stack spacing={4}>
                <Heading size="sm" fontWeight="semibold">
                  Assign an owner in Koala for {props.selectedRecords.length.toLocaleString()}{' '}
                  {pluralize('records', props.selectedRecords.length)}.
                </Heading>

                <Text fontSize="sm" color="gray.600">
                  This will assign ownership in Koala for these accounts, but it will not make changes in your CRM.
                </Text>

                <FormControl size="sm">
                  <FormLabel>Assign to...</FormLabel>

                  <UserSelector selectedUserId={assigneeId} onChange={setAssigneeId} />
                </FormControl>
              </Stack>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button type="button" variant="outline" size="sm" onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button type="submit" form={formId} size="sm" colorScheme="purple" isLoading={isLoading}>
              Assign owners
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

interface RemoveFromListProps {
  listId: string
  selectedRecords: string[]
}

function RemoveFromList(props: RemoveFromListProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const formId = 'remove-from-list-form'

  const { isLoading, mutateAsync: removeItems } = useRemoveListItems()

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault()

      try {
        await removeItems({ listId: props.listId, recordIds: props.selectedRecords })
        toast.success('Removed records from list')
        onClose()
      } catch (error: any) {
        toast.error('Failed to remove records from this list', { description: error?.message })
        console.error(error)
      }
    },
    [removeItems, onClose, props.listId, props.selectedRecords]
  )

  return (
    <>
      <Button
        size="sm"
        variant="outline"
        leftIcon={<IconCircleX size={16} />}
        iconSpacing={1.5}
        onClick={onOpen}
        isLoading={isLoading}
      >
        Remove from List
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />

          <ModalBody paddingTop={6}>
            <form id={formId} onSubmit={onSubmit}>
              <Stack spacing={4}>
                <Heading size="sm" fontWeight="semibold">
                  Remove {props.selectedRecords.length.toLocaleString()}{' '}
                  {pluralize('records', props.selectedRecords.length)}
                </Heading>

                <Text fontSize="sm" color="gray.600">
                  This will remove these records from the list. You can always add them back later.
                </Text>
              </Stack>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button type="button" variant="outline" size="sm" onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button type="submit" form={formId} size="sm" colorScheme="red" isLoading={isLoading}>
              Remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
