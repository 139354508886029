import React, { useCallback } from 'react'
import {
  MenuItem,
  IconButton,
  IconButtonProps,
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Button,
  Flex,
  Box,
  Heading
} from '@chakra-ui/react'
import { IconTableDown } from '@tabler/icons-react'
import { toast } from 'sonner'
import { arrify } from '../../lib/api'
import { ColumnSelector, keysToColumns, ManageColumns } from './ColumnSelector'
import { mergeParams } from '../pages/icps/types'
import { App } from '../../types/App'

async function requestDownload(url: string) {
  const response = await fetch(url, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  })

  const status = response.status
  let content
  let message

  // grab the filename from the headers, if possible
  const contentDisposition = response.headers.get('Content-Disposition')
  const filename = contentDisposition ? contentDisposition.split('filename=')[1] : 'download.csv'

  if (response.status === 200) {
    // the file was returned! use the blob
    content = await response.blob()
  } else if (response.status === 202) {
    // the file is being generated in the background
    message = (await response.json()).message || 'A download link will be emailed to you shortly.'
  } else if (!response.ok) {
    // something went wrong
    let body
    try {
      body = await response.json()
    } catch (e) {
      body = {}
    }
    // Attempt to parse error messages from the response body
    let message = arrify(body?.errors ?? body?.error)
      .filter(String)
      .join(',')
    if (!message) {
      message = String(status)
    }

    const error: any = new Error(message)
    error.statusCode = status
    error.body = body
    throw error
  }

  return { status, filename, content, message }
}

const handleCsvDownload = async (url: string) => {
  const csvPromise = requestDownload(url)

  toast.promise(csvPromise, {
    loading: 'Creating CSV export...',
    success: (data) => {
      return data.content
        ? 'Export finished successfully.'
        : data.message || 'A download link will be emailed to you shortly.'
    },
    error: 'There was an error creating your CSV'
  })

  const { filename, content } = await csvPromise
  if (content) {
    const downloadUrl = window.URL.createObjectURL(content)
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
}

interface DownloadCsvButtonProps extends IconButtonProps {
  isDisabled?: boolean
  url: string
}

export function DownloadCsvButton({ isDisabled, url, ...props }: DownloadCsvButtonProps) {
  const [isDownloading, setIsDownloading] = React.useState(false)

  const handleButtonClick = async (url: string) => {
    setIsDownloading(true)
    await handleCsvDownload(url)
    setIsDownloading(false)
  }

  return (
    <>
      <IconButton
        icon={<IconTableDown size={16} />}
        isDisabled={isDisabled || isDownloading}
        onClick={() => handleButtonClick(url)}
        {...props}
      />
    </>
  )
}

const disabledColumns = ['visitor_stats']

interface DownloadCsvMenuItemProps {
  url?: string
  isMenuDisabled?: boolean
  apps?: App[]
  audienceKind?: 'profile' | 'account'
  initialColumns?: string[]
  allowColumnSelection?: boolean
  itemTitle?: string | null
}

export default function DownloadCsvMenuItem({
  url,
  initialColumns,
  apps,
  audienceKind,
  isMenuDisabled,
  allowColumnSelection,
  itemTitle
}: DownloadCsvMenuItemProps) {
  const fetchUrl = url || `${window.location.pathname}.csv${window.location.search}`
  const disclosure = useDisclosure()

  const [selectedColumns, setSelectedColumns] = React.useState<any[]>(
    initialColumns && audienceKind
      ? keysToColumns(
          initialColumns.filter((c) => !disabledColumns.includes(c)),
          audienceKind
        )
      : []
  )

  const onRemoveColumn = useCallback((column) => {
    setSelectedColumns((columns) => columns.filter((c) => c.key !== column.key))
  }, [])

  const onSelectColumn = useCallback((column) => {
    setSelectedColumns((columns) => {
      if ((columns || []).length <= 20) {
        return columns.concat([column])
      }

      return columns
    })
  }, [])

  const onClose = disclosure.onClose
  const requestDownload = useCallback(() => {
    let url = fetchUrl

    if (allowColumnSelection && selectedColumns.length > 0) {
      url = mergeParams(fetchUrl, { columns: selectedColumns.map((c) => c.key).join(',') })
    }

    handleCsvDownload(url)
    onClose()
  }, [fetchUrl, onClose, selectedColumns, allowColumnSelection])

  return (
    <>
      <Modal size="2xl" {...disclosure}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody padding={0}>
            <Flex alignItems="stretch">
              <Box
                flex="1 1 50%"
                display="flex"
                flexDir="column"
                py={2}
                minHeight="250px"
                maxHeight="450px"
                borderRight="1px solid"
                borderColor="gray.200"
                overflow="auto"
              >
                <Box paddingX={4} paddingTop={2}>
                  <Heading size="xs">Select columns to include</Heading>
                </Box>
                <ColumnSelector
                  apps={apps}
                  selectedColumns={selectedColumns}
                  disabledColumns={disabledColumns}
                  audienceKind={audienceKind || 'account'}
                  onSelectColumn={onSelectColumn}
                  onRemoveColumn={onRemoveColumn}
                />
              </Box>
              <Box
                flex="1 1 50%"
                display="flex"
                flexDir="column"
                py={2}
                minHeight="250px"
                maxHeight="450px"
                overflow="auto"
              >
                <ManageColumns
                  header={
                    <Box paddingX={3} paddingTop={1} paddingBottom={3}>
                      <Heading size="xs">
                        {selectedColumns.length ? 'Selected columns:' : 'Select some columns first...'}
                      </Heading>
                    </Box>
                  }
                  selectedColumns={selectedColumns}
                  setSelectedColumns={setSelectedColumns}
                  onRemoveColumn={onRemoveColumn}
                />
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter
            borderTop="1px solid"
            borderColor="gray.200"
            bg="gray.50"
            roundedBottom="lg"
            justifyContent="flex-end"
            gap={3}
            p={3}
          >
            <Button size="sm" colorScheme="purple" onClick={requestDownload}>
              Export CSV
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <MenuItem
        icon={<IconTableDown size={16} />}
        isDisabled={isMenuDisabled}
        onClick={allowColumnSelection ? disclosure.onOpen : requestDownload}
        title={itemTitle || 'Export as CSV'}
      >
        Export as CSV{allowColumnSelection ? '...' : ''}
      </MenuItem>
    </>
  )
}
